import {
  CELLULAR_TUBULAR_SOILSENSE_DEPTHS,
  CELLULAR_TUBULAR_SOILSENSE_MOISTURE_PREFIX,
  CELLULAR_TUBULAR_SOILSENSE_TEMPERATURE_PREFIX
} from '@/components/DashboardPageV2/utils/TubularSoilsense';
import { I18n } from 'aws-amplify'


const depthLabels = ['10cm', '20cm', '30cm', '40cm', '50cm', '60cm', '70cm', '80cm', '90cm'];

export const strings = CELLULAR_TUBULAR_SOILSENSE_DEPTHS.reduce((acc, depth, index) => {
  acc[`${CELLULAR_TUBULAR_SOILSENSE_TEMPERATURE_PREFIX}${depth}`] = I18n.get(depthLabels[index]);
  acc[`${CELLULAR_TUBULAR_SOILSENSE_MOISTURE_PREFIX}${depth}`] = I18n.get(depthLabels[index]);
  return acc;
}, {});