import { I18n } from 'aws-amplify'

import { useMobileDashboard } from '../../context'
import { useDashboardParams } from '@/components/DashboardPageV2/utils/useDashboardParams'

import LiveDataTile from './LiveDataTile'
import { Text, Box } from '../../../../../primitives'
import { getDeviceTypes } from '@/reducers/selectors'

import './index.scss'
import TubularSoilSenseLiveData from '../TubularSoilsense/LiveData'
import {
  CELLULAR_TUBULAR_SOILSENSE_DEPTHS,
  CELLULAR_TUBULAR_SOILSENSE_MOISTURE,
  CELLULAR_TUBULAR_SOILSENSE_MOISTURE_PREFIX,
  CELLULAR_TUBULAR_SOILSENSE_TEMPERATURE,
  CELLULAR_TUBULAR_SOILSENSE_TEMPERATURE_PREFIX
} from '@/components/DashboardPageV2/utils/TubularSoilsense'

export function isCellularTubularSoilsenseMeasure(measureId) {
  return (
    [
      CELLULAR_TUBULAR_SOILSENSE_TEMPERATURE,
      CELLULAR_TUBULAR_SOILSENSE_MOISTURE
    ].includes(measureId) ||
    CELLULAR_TUBULAR_SOILSENSE_DEPTHS.map(v => [
      `${CELLULAR_TUBULAR_SOILSENSE_TEMPERATURE_PREFIX}${v}`,
      `${CELLULAR_TUBULAR_SOILSENSE_MOISTURE_PREFIX}${v}`
    ])
      .flat()
      .includes(measureId)
  )
}

export default function LiveData({ zoneMeasurements, zoneId, isRoom }) {
  const { state } = useMobileDashboard()
  const dashboardParams = useDashboardParams()
  const { devices } = state?.currentZone
  const deviceTypes = getDeviceTypes()

  let measurementsToShow = [...zoneMeasurements]
  let hasSoilSenseMoisture = measurementsToShow.includes(
      CELLULAR_TUBULAR_SOILSENSE_MOISTURE
    ),
    hasSoilSenseTemperature = measurementsToShow.includes(
      CELLULAR_TUBULAR_SOILSENSE_TEMPERATURE
    )
  const showSoilSenseWidget = () =>
    hasSoilSenseMoisture || hasSoilSenseTemperature

  const currentDevice = devices.find(({ id }) => id === zoneId)

  if (currentDevice && deviceTypes?.length > 0) {
    const { sensorType } = devices.find(({ id }) => id === zoneId)
    const deviceType = deviceTypes.find(type => type.id === sensorType)
    const { measurements } = deviceType
    measurementsToShow = zoneMeasurements.filter(id =>
      measurements.includes(id)
    )
  }

  if (
    measurementsToShow?.includes('temperature') &&
    measurementsToShow?.includes('humidity') &&
    !measurementsToShow?.includes('vapour_pressure_deficit')
  ) {
    measurementsToShow.push('vapour_pressure_deficit')
  }

  measurementsToShow = measurementsToShow.filter(
    id => !isCellularTubularSoilsenseMeasure(id)
  )

  if (measurementsToShow?.length === 0) {
    return (
      <Box style={{ margin: '0.8rem 0' }}>
        <Text tone={800} variant='page'>
          {I18n.get('No data available')}
        </Text>
      </Box>
    )
  }

  return (
    <div className='LiveDataWidget'>
      {showSoilSenseWidget() && (
        <div className='LiveDataWidget__FullWidth'>
          <TubularSoilSenseLiveData
            hasMoisture={hasSoilSenseMoisture}
            hasTemperature={hasSoilSenseTemperature}
            sensorId={dashboardParams.sensorId}
          />
        </div>
      )}
      {measurementsToShow.map(measurementId => {
        return (
          <div className='LiveDataWidget__GridItem' key={measurementId}>
            <LiveDataTile
              measurementId={measurementId}
              zoneId={zoneId}
              isRoom={isRoom}
            />
          </div>
        )
      })}
    </div>
  )
}
