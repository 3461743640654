import HighchartsComponent from '@/components/Shared/HighchartsComponent'
import { Fragment } from 'react'
import strings from '../strings'
import { getMobileDashboardData } from '@/reducers/selectors'
import {
  CELLULAR_TUBULAR_SOILSENSE_MOISTURE_PREFIX,
  CELLULAR_TUBULAR_SOILSENSE_TEMPERATURE_PREFIX,
  CELLULAR_TUBULAR_SOILSENSE_DEPTHS,
  CELLULAR_TUBULAR_SOILSENSE_DEPTH_COLORS
} from '@/components/DashboardPageV2/utils/TubularSoilsense'

import './index.scss'

const TubularSoilSenseLiveData = ({
  hasTemperature,
  hasMoisture,
  sensorId
}) => {
  const data = getMobileDashboardData()
  const numberOfDecimals = 1

  const getData = (prefix, isMoisture = false) => {
    const fullData = CELLULAR_TUBULAR_SOILSENSE_DEPTHS.map(depth => {
      let value = +data[sensorId]?.[`${prefix}${depth}`]?.current?.value
      if (!value) return null

      let y = Number(
        (isMoisture ? value * -1 : value).toFixed(numberOfDecimals)
      )

      const color = CELLULAR_TUBULAR_SOILSENSE_DEPTH_COLORS[depth]
      return isMoisture
        ? { y, borderColor: color, borderWidth: '2px' }
        : { y, color }
    })
    const lastNonNullIndex = fullData.findLastIndex(item => item !== null)
    return fullData.slice(0, lastNonNullIndex + 1)
  }

  const temperatureData = hasTemperature
    ? getData(CELLULAR_TUBULAR_SOILSENSE_TEMPERATURE_PREFIX)
    : []
  const moistureData = hasMoisture
    ? getData(CELLULAR_TUBULAR_SOILSENSE_MOISTURE_PREFIX, true)
    : []

  const numberOfDepthsWithData = Math.max(
    temperatureData.length,
    moistureData.length
  )

  const categories = CELLULAR_TUBULAR_SOILSENSE_DEPTHS.sort((a, b) => a > b)
    .slice(0, numberOfDepthsWithData)
    .map(depth => strings[`${depth}Depth`])

  const createYAxis = (
    labelFormat,
    unit,
    titleText,
    isOpposite,
    width,
    left
  ) => ({
    labels: { format: labelFormat, unit, useHTML: true },
    title: { text: titleText },
    opposite: isOpposite,
    width,
    left,
    reversed: false,
    startOnTick: true
  })

  const createSeries = (name, data, yAxisIndex, unit, isMoisture = false) => ({
    name,
    yAxis: yAxisIndex,
    data,
    tooltip: {
      headerFormat: '',
      pointFormat: `<b>{series.name} at {point.category}</b><br/>{(${
        isMoisture ? 'multiply -1 point.y' : 'point.y'
      }):.2f}${unit}`
    },
    dataLabels: {
      format: `{${isMoisture ? 'multiply y -1' : 'y'}}${unit}`
    }
  })

  const chartOptions = {
    chart: { type: 'bar' },
    title: { text: strings.chartTitle },
    xAxis: [{ categories }],
    yAxis: [],
    legend: { enabled: false },
    plotOptions: {
      series: { stacking: 'normal', borderRadius: '50%' },
      bar: {
        borderRadius: '50%',
        grouping: true,
        dataLabels: { enabled: true },
        groupPadding: 0.0
      }
    },
    series: []
  }

  if (hasTemperature && temperatureData.length) {
    chartOptions.yAxis.push(
      createYAxis(
        '{value}°C',
        'ºC',
        strings.temperature,
        hasMoisture,
        hasMoisture ? '50%' : '100%',
        hasMoisture ? '50%' : '0%'
      )
    )
    chartOptions.series.push(
      createSeries(strings.temperature, temperatureData, 0, 'ºC')
    )
  }

  if (hasMoisture && moistureData.length) {
    chartOptions.yAxis.push(
      createYAxis(
        '{multiply value -1}%',
        '%',
        strings.moisture,
        false,
        hasTemperature ? '50%' : '100%'
      )
    )
    chartOptions.series.push(
      createSeries(
        strings.moisture,
        moistureData,
        hasTemperature ? 1 : 0,
        '%',
        true
      )
    )
  }

  return (
    <Fragment>
      <HighchartsComponent options={chartOptions} />
    </Fragment>
  )
}

export default TubularSoilSenseLiveData
