import useDatasetData from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Hooks/useDatasetData'

import Line from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Chart/Line'
import {
  CELLULAR_TUBULAR_SOILSENSE_MOISTURE,
  CELLULAR_TUBULAR_SOILSENSE_TEMPERATURE,
  CELLULAR_TUBULAR_SOILSENSE_DEPTHS,
  CELLULAR_TUBULAR_SOILSENSE_DEPTH_COLORS
} from '@/components/DashboardPageV2/utils/TubularSoilsense'

import { strings } from '../strings'
import { getDevice, getDeviceTypes } from '@/reducers/selectors'

export default function DatasetLine({ options, config, unit, ...props }) {
  let measureId

  const { sensorType } = getDevice()
  const deviceMeasurements = getDeviceTypes()?.find(
    ({ id }) => id === sensorType
  )?.measurements

  if (options.measurement === CELLULAR_TUBULAR_SOILSENSE_TEMPERATURE) {
    measureId = 't'
  } else if (options.measurement === CELLULAR_TUBULAR_SOILSENSE_MOISTURE) {
    measureId = 'm'
  } else {
    throw Error(`Widget implementation for ${options.measurement} not found.`)
  }

  const measurements = CELLULAR_TUBULAR_SOILSENSE_DEPTHS.map(
    d => `s_${measureId}_${d}`
  ).filter(v => deviceMeasurements.includes(v))

  const { datasetData, sensorId } = useDatasetData({
    measurements,
    range: config.range,
    interval: config.interval,
    includeSensor: true
  })

  let chartData = []

  if (datasetData) {
    for (const [index, measure] of measurements.entries()) {
      const data = datasetData?.[measure]?.[sensorId]
      if (!data) continue
      chartData.push({
        id: `${strings[measure]}`,
        data,
        color:
          CELLULAR_TUBULAR_SOILSENSE_DEPTH_COLORS[
            CELLULAR_TUBULAR_SOILSENSE_DEPTHS[index]
          ]
      })
    }
  }

  return <Line {...props} data={chartData} unit={unit} config={config} />
}
